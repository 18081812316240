/*import Swiper, { Navigation, Grid, Autoplay, Mousewheel } from 'swiper';*/
import Swiper, { Navigation, Grid, Autoplay, Mousewheel } from 'swiper';

const reviewSlider = document.getElementsByClassName(
  'review-section__slider'
)[0];

if (reviewSlider) {
  window.addEventListener('load', function () {
    const reviewSlidesArr = reviewSlider.querySelectorAll('.swiper-slide');
    const swiperWrapper = reviewSlider.querySelector('.swiper-wrapper');
    let slidesCount = reviewSlidesArr.length;
    let isLoop = swiperWrapper.scrollHeight > swiperWrapper.clientHeight;
    let slidesPerView = slidesCount <= 4 ? slidesCount : 4;
    let mb = 10;

    let height = null;

    if (slidesCount <= 4) {
      reviewSlider.style.height = `${
          (reviewSlidesArr[0].clientHeight + mb) * slidesCount - mb
      }px`;
      slidesPerView =  reviewSlidesArr[0].clientHeight;
    } else {
      reviewSlider.style.maxHeight = `${
          (reviewSlidesArr[0].clientHeight + mb) * slidesPerView  - mb
      }px`;
    }

   setTimeout(function(){
     const reviewSwiper = new Swiper('.review-section__slider', {
       modules: [Navigation, Grid, Autoplay, Mousewheel],
       spaceBetween: 10,
       threshold: 10,
       loop: isLoop,
       loopedSlides: 10,
       autoplay: {
         delay: 3500,
         disableOnInteraction: false,
         pauseOnMouseEnter: true,
       },
       direction: 'vertical',
       slidesPerView: slidesPerView,
       grid: {
         rows: 1,
         column: 1,
       },
       mousewheel: {
         forceToAxis: true,
         sensitivity: 1,
         releaseOnEdges: true,
       },
       /*navigation: {
           prevEl:
             '.global-opportunities-slider-buttons.slider-buttons .slider-btn.prev',
           nextEl:
             '.global-opportunities-slider-buttons.slider-buttons .slider-btn.next',
         },*/
     });
   }, 100)
  });
}
