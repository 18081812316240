let currencyGroup = [...document.getElementsByClassName('js-currency-wr')];
if(currencyGroup){
    currencyGroup.forEach(wrapper => {
        let selects = wrapper.querySelectorAll('[data-choices-currency]');
        let selectMin = selects[0];
        let selectMax = selects[1];
        const choices1 = new Choices(selectMin, {
            searchEnabled: true,
            itemSelectText: '',
            placeholder: true,
            searchPlaceholderValue: 'Search',
        });
        const choices2 = new Choices(selectMax, {
            searchEnabled: true,
            itemSelectText: '',
            placeholder: true,
            searchPlaceholderValue: 'Search',
        });
        selectMin.addEventListener('change', function(){
            choices2.setChoiceByValue(this.value);
        })
        selectMax.addEventListener('change', function(){
            choices1.setChoiceByValue(this.value);
        })
    })

}