let inputTel = document.querySelectorAll('input[type="tel"]');
if (inputTel) {
  inputTel.forEach((input) => {
    input.addEventListener('keydown', function (e) {
      let regKeys = /^[a-zA-Z]$/gi.test(e.key);
      if (regKeys) {
        e.preventDefault();
      }
    });
  });
}
