Splitting();

function parallax(e) {
  const elements = document.querySelectorAll('.decor-parallax');

  elements.forEach((elem) => {
    const speed = elem.getAttribute('data-speed');
    const rect = elem.getBoundingClientRect();

    if (rect.top < window.innerHeight && rect.bottom > 0) {
      const x = (window.innerWidth - e.pageX * speed) / 400;
      const y = (window.innerHeight - e.pageY * speed) / 400;

      elem.style.transform = `translateX(${x}px) translateY(${y}px)`;
      /*elem.style.transform = `translateY(${y}px)`;*/
    }
  });
}

if (
  window.innerWidth >= 1025 &&
  document.getElementsByClassName('decor-parallax')[0]
) {
  document.addEventListener('mousemove', parallax);
}
