window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();


console.log('ewcce');

window.passwordInputs = function passwordInputs() {
  if (document.querySelector('.password-input')) {
    const passwordInputsArr = document.querySelectorAll('.password-input');
    passwordInputsArr.forEach((password) => {
      const btn = password.querySelector('.password-input__btn');
      const input = password.querySelector('input');

      btn.addEventListener('click', () => {
        inputIconFunc();
      });

      function inputIconFunc() {
        if (!btn.classList.contains('is-password-hidden')) {
          btn.classList.add('is-password-hidden');
          input.type = 'password';
        } else {
          btn.classList.remove('is-password-hidden');
          input.type = 'text';
        }
      }
      inputIconFunc();
    });
  }
};

passwordInputs();

window.linkInputFunc = function linkInputFunc() {
  if (document.querySelector('.link-input')) {
    const input = document.querySelector('.link-input');
    const baseLink = input.dataset.link;

    const initialValue = input.value.replace(baseLink, '');

    input.value = baseLink + initialValue;

    input.addEventListener('input', function () {
      const userInput = input.value.slice(baseLink.length);

      input.value = baseLink + userInput;
    });

    input.addEventListener('beforeinput', function (event) {
      if (
          (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward') &&
          input.selectionStart <= baseLink.length
      ) {
        event.preventDefault();
      }
    });

    input.addEventListener('click', function () {
      if (input.selectionStart < baseLink.length) {
        input.setSelectionRange(baseLink.length, baseLink.length);
      }
    });

    input.addEventListener('focus', function () {
      setTimeout(() => {
        if (input.selectionStart < baseLink.length) {
          input.setSelectionRange(input.value.length, input.value.length);
        }
      }, 0);
    });
  }
};

linkInputFunc();

window.infoInputFunc = function infoInputFunc() {
  if (document.querySelector('.info-wrap')) {
    const infoWrapsArr = document.querySelectorAll('.info-wrap');
    infoWrapsArr.forEach((infoWrap) => {
      infoWrap.addEventListener('click', (e) => {
        const isInputOrLabelClick = e.target.tagName.toLowerCase() === 'input' || e.target.tagName.toLowerCase() === 'label';

        if (!isInputOrLabelClick) {
          e.preventDefault();
          infoWrap.classList.toggle('active');
        }
      });
    });
  }
}

infoInputFunc();


