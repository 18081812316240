var popupFD = document.querySelector('#popup');
if (popupFD) {
    /*var popupTitle = popupFD.querySelector(".popup__title");*/
    var popupBlock = popupFD.querySelector('#popup-block');

    window.stopVideoPlay = function stopVideoPlay() {
        if (window.player) {

            window.player.pause();
        }
    };


    window.popupVideoPlayer = function popupVideoPlayer() {
        const videoPlayer = document.getElementsByClassName('popup-video-player')[0];
        if (videoPlayer) {
            const videoPlayersArr = document.querySelectorAll('.popup-video-player');
            for (let i = 0; i < videoPlayersArr.length; i++) {
                const player = new Plyr(videoPlayersArr[i], {
                    controls: [
                        'play-large',
                        'play',
                        'progress',
                        'current-time',
                        'mute',
                        'volume',
                        'fullscreen',
                        'pip',
                        'airplay',
                    ],
                    tooltips: {controls: !0},
                    fullscreen: {iosNative: true},
                });

                player.on('enterfullscreen', function () {
                    console.log('enterfullscreen');
                });

                player.on('exitfullscreen', function () {
                    console.log('exitfullscreen');
                });

                // Expose player so it can be used from the console
                window.player = player;

                if (window.innerWidth >= 1024) {
                    setTimeout(function () {
                        const plyrArr = document.querySelectorAll('.plyr');
                        for (let j = 0; j < plyrArr.length; j++) {
                            plyrArr[i].classList.add('plyr-hide-controls');
                        }

                        plyrArr[i].addEventListener('mouseenter', () => {
                            plyrArr[i].classList.remove('plyr-hide-controls');
                        });
                    }, 300);
                }

                /*player.play();*/
            }
        }
    };
