let filtersButton = document.getElementsByClassName('show-form-filters-button')[0];
function slideToggle(element) {
  var content = document.querySelector(element);
  if (content.style.maxHeight) {
    content.style.maxHeight = null;

      content.style.overflow = 'hidden'

  } else {
    content.style.maxHeight = content.scrollHeight + 'px';
    setTimeout(function(){
      content.style.overflow = 'visible'
    }, 300)
  }
}

if(filtersButton){
  filtersButton.addEventListener('click', function(){
    this.classList.toggle('active');
    slideToggle('.find-recruiter-form-filters-hidden')
  })
}

