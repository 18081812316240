import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let dashboardCardsSwiperWr = document.querySelectorAll(
  '.dashboard-cards-swiper-wr'
);
dashboardCardsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let slidesAuto = swiperEl.classList.contains('slides-auto');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      spaceBetween: 15,
      slidesPerView: slidesAuto ? 'auto' : 1,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        550: {
          slidesPerView: slidesAuto ? 'auto' : 2,
        },
        1025: {
          spaceBetween: 26,
          slidesPerView: slidesAuto ? 'auto' : 3,
        },
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });

    swiperObserver(swiper);
  }
});
