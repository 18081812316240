import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

window.initOwnReviewSwiper = function initOwnReviewSwiper(){
  let ownReviewSwiperWr = document.querySelectorAll('.own-review-swiper-wr');
  ownReviewSwiperWr.forEach((el) => {
    if (el) {
      let swiperEl = el.querySelector('.swiper');
      let nextEl = el.querySelector('.swiper-button-next');
      let prevEl = el.querySelector('.swiper-button-prev');
      let slidesCount = el.querySelectorAll('.swiper-slide').length;
      let swiper = new Swiper(swiperEl, {
        modules: [Autoplay, Navigation],
        spaceBetween: 16,
        slidesPerView: 1,
        speed: 800,
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },

        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        breakpoints: {
          651: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
        },
      });
      window.swipers.push(swiper);
      swiperObserver(swiper);
    }
  });
}

initOwnReviewSwiper();

let swichButtons = document.querySelectorAll('.dashboard-title-buttons .btn-profile-small');

if(swichButtons.length > 1){
  swichButtons.forEach((button, idx)=>{
    button.addEventListener('click', function(){
      swichButtons.forEach((button, targetIdx) =>{
        idx === targetIdx ? button.classList.add('active') : button.classList.remove('active');
      })
    })
  })
}
