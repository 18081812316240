window.initTariffSwitcher = function initTariffSwitcher(switchersWrapper, tabs){
  let switchWrapper = document.querySelector(switchersWrapper);
  if (switchWrapper) {
    let switcherButtons = switchWrapper.querySelectorAll('.switch-btn');
    let switcherButtonsBg = switchWrapper.querySelector('.switch-bg');
    let offerTabs = document.querySelectorAll(tabs);
    switcherButtons.forEach((button, idx) => {
      button.addEventListener('click', function () {
        switcherButtonsBg.style.left = this.offsetLeft + 'px';
        switcherButtonsBg.style.width = this.clientWidth + 'px';
        switcherButtons.forEach((button, i) =>
            i != idx ? button.classList.remove('active') : ''
        );
        offerTabs.forEach((tab, i) => {
          i != idx ? tab.classList.remove('active') : '';
        });
        this.classList.add('active');
        offerTabs[idx].classList.add('active');
      });

      if (idx === 1) {
        /*set default active to second button & tab*/
        setTimeout(function () {
          offerTabs[idx].classList.add('active');
          switcherButtonsBg.classList.add('active');
          button.classList.add('active');
          switcherButtonsBg.style.left = button.offsetLeft + 'px';
          switcherButtonsBg.style.width = button.clientWidth + 'px';
        }, 50);
      }
    });
  }

}
initTariffSwitcher('.offer-section-switches', '.offer-section-tab')