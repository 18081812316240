let dashBoardVideo = document.querySelectorAll('.profile-about-video-item');
if (dashBoardVideo) {
  dashBoardVideo.forEach((video) => {
    let plyr = new Plyr(video, {
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'fullscreen',

        'airplay',
      ],
      tooltips: { controls: !0 },
      fullscreen: { iosNative: true },
    });
    plyr.on('play', function () {
      this.classList.add('play-start');
    });
  });
}


window.initPlyrVideo = function initPlyrVideo(selector){
  let videoItems = document.querySelectorAll(selector);
  console.log(videoItems)
  if(videoItems){
    videoItems.forEach(video =>{
      let plyr = new Plyr(video, {
        controls: [
          'play-large',
          'play',
          'progress',
          'mute',
          'volume',
          'fullscreen',
          'airplay',
        ],
        hideControls: true,
        tooltips: { controls: !0 },
        fullscreen: { iosNative: true },
      });
      plyr.on('play', function () {
        this.classList.add('play-start');
      });
    })
  }
}
initPlyrVideo('.plyr-item');