import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let dashboardAgencyReviewSwiperWr = document.querySelectorAll(
  '.dashboard-agency-review-swiper-wr'
);
dashboardAgencyReviewSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay],
      spaceBetween: 7,
      slidesPerView: 1,
      loop: slidesCount > 1,
      slideToScroll: 1,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        450: {
          direction: 'vertical',
          slidesPerView: 2,
          loop: false,
        },
      },
    });
    swiperObserver(swiper);
  }
});
