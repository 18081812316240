window.initReadMoreFunc = function initReadMoreFunc() {
    let cards = [...document.getElementsByClassName('dashboard-review-card-short')];

    if (cards.length) {
        function isReadMore(){
            cards.forEach(card => {
                let content = card.querySelector('[data-show-more-content]');
                let readMoreBtn = card.querySelector('[data-show-more-toggle]');
                let isReadMore = content.scrollHeight > content.clientHeight;
                content.style.display = '';
                readMoreBtn.classList.remove('active');
                if (isReadMore) {
                    readMoreBtn.classList.add('show');
                }else{
                    readMoreBtn.classList.remove('show');
                }
            })
        }

        cards.forEach(card => {
            let content = card.querySelector('[data-show-more-content]');
            let readMoreBtn = card.querySelector('[data-show-more-toggle]');

            readMoreBtn.addEventListener('click', function () {
                if (this.classList.contains('active')) {
                    this.classList.remove('active');
                    content.style.display = '';
                } else {
                    content.style.display = 'block';
                    this.classList.add('active');
                }
            })
        })

        isReadMore()
        window.addEventListener('resize', isReadMore);
    }
}
initReadMoreFunc();
