import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

window.initTarrifSwiper = function initTarrifSwiper(wrapper, slidePerView=3){
  let offerCardsSwiperWr = document.querySelectorAll(wrapper);

  offerCardsSwiperWr.forEach((el) => {
    if (el) {
      let swiperEl = el.querySelector('.swiper');
      let nextEl = el.querySelector('.swiper-button-next');
      let prevEl = el.querySelector('.swiper-button-prev');
      let slidesCount = el.querySelectorAll('.swiper-slide').length;
      let offerCardsTitle = swiperEl.querySelector('.offer-card-title');
      let currentSlide = 0;
      let autoplayInterval;
      let swiper = new Swiper(swiperEl, {
        modules: [Autoplay, Navigation],
        spaceBetween: 20,
        slidesPerView: 1,

        /*   autoplay: {
             delay: 2000,
             disableOnInteraction: false,
             pauseOnMouseEnter: false,
           },*/

        speed: 800,
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          200: {
            autoHeight: true,
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1025: {
            slidesPerView: slidePerView,
          },
        },

        on: {
          resize: function () {
            if (this.params.slidesPerView > 1) {
              let titles = this.$el[0].querySelectorAll('.offer-card-title');
              setSameHeight(titles);
            }
          },
          afterInit: function () {
            if (this.params.slidesPerView > 1) {
              let titles = this.$el[0].querySelectorAll('.offer-card-title');
              setSameHeight(titles);
            }
          }
        },
      });
      /*fix autoplay not play bug*/

      /*swiperObserver(swiper);*/
    }
  });
}
initTarrifSwiper('.offer-swiper-wr');
