import Swiper, { Autoplay, Scrollbar, Mousewheel } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('community-section__slider')[0]) {
  const slidersArr = document.querySelectorAll('.community-section__slider');
  slidersArr.forEach((sliderEl, sliderIdx) => {


    const swiper = new Swiper(sliderEl, {
      modules: [Autoplay, Scrollbar, Mousewheel],
      slidesPerView: 1,
      spaceBetween: 30,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      breakpoints: {
        451: {
          spaceBetween: 20,
          slidesPerView: 1.5,
        },
        551: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        751: {
          spaceBetween: 20,
          slidesPerView: 2.5,
        },
        901: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 20,
          slidesPerView: 4,
        },
      },
      scrollbar: {
        el: '.community-section__scrollbar',
        draggable: true,
      },
    });

   /* swiperObserver(swiper);*/
  });
}
