import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let ideaCardsSwiperWr = document.querySelectorAll('.idea-swiper-wr');
ideaCardsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1025: {
          slidesPerView: 3,
        },
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    setTimeout(function () {
      swiper.update();
    }, 300);
    swiperObserver(swiper);
  }
});
