import Swiper, { Autoplay, EffectCreative } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let findRecruiterSwiperWr = document.querySelectorAll(
  '.find-recruiter-swiper-wr'
);
findRecruiterSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;

    const swiper = new Swiper(swiperEl, {
      modules: [Autoplay, EffectCreative],
      slidesPerView: 1,
      spaceBetween: 30,
      threshold: 10,
      effect: 'creative',
      creativeEffect: {
        limitProgress: 2,
        prev: {
          translate: [0, 0, -320],
          opacity: 0,
        },
        next: {
          effect: 'scale',
          scale: 0.92,
          translate: [0, 'calc(41%)', -1],
          opacity: 0.8,
        },
      },
      /*slideToClickedSlide: true,*/
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth > 1024,
      },
    });

    swiperObserver(swiper);
  }
});
