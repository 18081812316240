window.setSameHeight = function setSameHeight(elArr) {
    let minHeight = 0;
    if (elArr) {
        elArr.forEach((el) => {
            if (minHeight < el.clientHeight) {
                minHeight = el.clientHeight;
            }
        });
        elArr.forEach((el) => (el.style.minHeight = minHeight + 'px'));
    }
}