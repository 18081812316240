/**
 * Function for disabling Swiper autoscroll when Swiper is not in viewport
 */
export const swiperObserver = (swiperInstance, autoplayInterval) => {
  if (swiperInstance.autoplay && swiperInstance.autoplay.running) {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.85,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          swiperInstance.autoplay.start();
        } else {
          swiperInstance.autoplay.stop();
        }
      });
    }, observerOptions);

    observer.observe(swiperInstance.el);
  }
};

window.swipers = [];

window.updateSwiper = function updateSwiper(selector){
  let swiperEl = typeof selector === 'object' ? selector : document.querySelector(`${selector}`);
  if (swiperEl){
    window.swipers.forEach(swiper=>{
      if(swiper.el === swiperEl || swiper === swiperEl){
        setTimeout(function(){
          swiper.update();
          console.log('swiper - updated');
        }, 100)

      }else{
        console.log('swiper - is not updated');
      }
    })
  }
}
/*updateSwiper('#jobs-section .swiper');
    updateSwiper(swiper);*/
