let uploadButtons = document.querySelectorAll('.upload-btn-wr');
if (uploadButtons) {
  uploadButtons.forEach(button => {
    let file = button.querySelector('input[type=file]');
    let uploadStatus = button.querySelector('.upload-status');
    file.addEventListener('change', function() {
        if (this.files[0]) {
          uploadStatus.innerHTML = this.files[0].name;
        }
      },
    );
  });
}
