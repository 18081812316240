window.setReviewPopupRate = function setReviewPopupRate() {
    let rateStarWrapper = [...document.getElementsByClassName('review-popup-rate-wr')];

    if (rateStarWrapper) {
        rateStarWrapper.forEach(wrapper => {
            let rateInput = wrapper.querySelector('.rate-info-input');
            if (rateInput) {
                let starButtons = wrapper.querySelectorAll('.icon-star');
                let rateSpan = wrapper.querySelector('.rate-span');
                let currentRate = parseFloat(rateInput.value);
                starButtons.forEach((button, idx) => {
                    button.addEventListener('click', function (e) {
                        let decimal = this.clientWidth / 2 >= e.offsetX ? 0.5 : 0;
                        let targetIdx = idx + 1;
                        let val = targetIdx - decimal;
                        rateInput.value = val;
                        rateSpan.innerText = val;
                        initDecimalRate(val, wrapper, '.icon-star');
                    })
                })

                if (currentRate > 0) {
                    rateSpan.innerText = currentRate;
                    initDecimalRate(currentRate, wrapper, '.icon-star');
                }
            }
        })
    }
}


window.initDecimalRate = function initDecimalRate(val, selectorWrapper, rateSelector) {

    let wrapper = typeof selectorWrapper === 'string' ? document.querySelector(selectorWrapper) : selectorWrapper;
    if (val > 5) {
        console.log(`Rating value must be < 6 value = ${val}`);
    }

    console.log(wrapper)

    if (wrapper) {
        let rateArr = wrapper.querySelectorAll(rateSelector);
        let value = val > rateArr.length ? rateArr.length : parseFloat(val);
        if (rateArr.length) {
            let decimal = Number.isInteger(value) ? 0 : 1;
            let targetIdx = Math.round(value) - 1;

            rateArr.forEach((item, idx) => {
                if (idx <= targetIdx) {
                    item.classList.add('active');
                    item.classList.remove('decimal');
                    if (decimal === 1) {
                        rateArr[targetIdx].classList.add('decimal');
                    }
                } else {
                    item.classList.remove('active');
                    item.classList.remove('decimal');
                }
                item.classList.add('rate-initialized')
            })
        }
    }
}

let rateArr = document.querySelectorAll('[data-rate]');

if(rateArr.length){
    rateArr.forEach(el =>{
     let value = parseFloat(el.dataset.rate);
     initDecimalRate(value, el, '.icon-star');
    })
}

window.updateDecimalRate = function updateDecimalRate(rateArr){
    if(rateArr.length){
        rateArr.forEach(el =>{
            let value = parseFloat(el.dataset.rate);
            initDecimalRate(value, el, '.icon-star');
        })
    }
}

if(document.querySelector('.reviews-buttons')) {
    const reviewsBtns = document.querySelectorAll('.reviews-buttons .btn');
    reviewsBtns.forEach((btn, i) => {

        btn.addEventListener('click', () => {
            for (let j = 0; j < reviewsBtns.length; j++) {
                reviewsBtns[j].classList.remove('active');
            }

            btn.classList.add('active');
        })
    })
}
